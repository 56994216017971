const refs = 'videoConsultations'
export default [

    {
        path: '/' + refs,
        name: refs,
        component: () => import('@/views/' + refs + '/List.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/status/:status',
        name: 'status-' + refs,
        component: () => import('@/views/' + refs + '/List.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/filter/:filter_by/:filter',
        name: 'filter-' + refs,
        component: () => import('@/views/' + refs + '/List.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/create',
        name: 'create-' + refs,
        component: () => import('@/views/' + refs + '/EditOrNew.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/edit/:id',
        name: 'edit-' + refs,
        component: () => import('@/views/' + refs + '/EditOrNew.vue'),
        beforeEnter: requireAuth
    },

]



function requireAuth(to, from, next) {
    if (!localStorage.getItem('access_token')) { next('/login'); }
    else { next(); }
}