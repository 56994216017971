const refs = 'financialDues'
export default [

    {
        path: '/' + refs,
        name: refs,
        component: () => import('@/views/' + refs + '/Card.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/show',
        name: 'show-' + refs,
        component: () => import('@/views/' + refs + '/List.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/status/:status',
        name: 'status-' + refs,
        component: () => import('@/views/' + refs + '/List.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/filter/:filter_by/:filter',
        name: 'filter-' + refs,
        component: () => import('@/views/' + refs + '/List.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/create',
        name: 'create-' + refs,
        component: () => import('@/views/' + refs + '/EditOrNew.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/edit/:id',
        name: 'edit-' + refs,
        component: () => import('@/views/' + refs + '/BookSetting.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/edit/:id',
        name: 'edit-meeting-' + refs,
        component: () => import('@/views/' + refs + '/BookSettingMeeting.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/edit/price/:id',
        name: 'edit-price-' + refs,
        component: () => import('@/views/' + refs + '/PriceSetting.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/:id',
        name: 'edit-amount-' + refs,
        component: () => import('@/views/' + refs + '/AmountSetting.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/edit/status/:id',
        name: 'edit-status-' + refs,
        component: () => import('@/views/' + refs + '/StatusSetting.vue'),
        beforeEnter: requireAuth
    },

]



function requireAuth(to, from, next) {
    if (!localStorage.getItem('access_token')) { next('/login'); }
    else { next(); }
}