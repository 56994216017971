const refs = 'reports'
export default [

    {
        path: '/' + refs,
        name: refs,
        component: () => import('@/views/' + refs + '/List.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/:type',
        name: 'show-' + refs,
        component: () => import('@/views/' + refs + '/List.vue'),
        beforeEnter: requireAuth
    },
    {
        path: '/' + refs + '/:type' + '/create' + '/:encryptId',
        name: 'create-' + refs,
        component: () => import('@/views/' + refs + '/EditOrNew.vue'),
        beforeEnter: requireAuth
    }

]



function requireAuth(to, from, next) {
    if (!localStorage.getItem('access_token')) { next('/login'); }
    else {
        next();
    }
}