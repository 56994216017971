export default [
    
    { 
        path: '/', 
        name: 'home', 
        component: () => import('@/views/dashboard/List.vue'),
        beforeEnter: requireAuth 
    },
    { 
        path: '/dashboard', 
        name: 'dashboard', 
        component: () => import('@/views/dashboard/List.vue'),
        beforeEnter: requireAuth 
    },
    { 
        path: '/applications', 
        name: 'applications', 
        component: () => import('@/views/applications/List.vue'),
        beforeEnter: requireAuth 
    },
    { 
        path: '/mobileSidebar', 
        name: 'mobileSidebar', 
        component: () => import('@/components/MobileSidebar.vue'),
        beforeEnter: requireAuth 
    },

]



function requireAuth(to, from, next) {
    // store.dispatch('fetchAccessToken');
    // if (!store.state.accessToken) { next('/login'); } 
    // else { next(); }
    if(!localStorage.getItem('access_token')) { next('/login'); }
    else { next(); }
}