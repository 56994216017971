var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"vertical-menu"},[_c('div',{staticClass:"h-100",attrs:{"data-simplebar":""}},[_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_c('li',{key:"t-menu",staticClass:"menu-title font-size-14",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria' : ''},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? "القائمة" : "Menu")+" ")]),(!_vm.pgLoading)?_c('li',[_c('router-link',{staticClass:"waves-effect",class:[
                _vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-17'
                  : 'font-size-15',
                this.$route.path == '/' ? 'active' : '',
              ],attrs:{"to":"/"}},[_c('i',{staticClass:"bx bx-customize font-size-22"}),_c('span',{key:"t-dashboards"},[_vm._v(" "+_vm._s(_vm.$t("nav.dashboard"))+" ")])])],1):_vm._e(),_vm._l((_vm.items),function(item,idx){return _c('li',{key:idx},[_c('router-link',{staticClass:"waves-effect",class:[
                _vm.$i18n.locale == 'ar'
                  ? 'hacen_algeria font-size-17'
                  : 'font-size-15',
                item.authority ? '' : 'hidden',
              ],attrs:{"to":'/' + item.slug,"active-class":"active"}},[_c('i',{staticClass:"font-size-22",class:item.icon}),_c('span',{key:"t-dashboards"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? item.en.name : item.ar.name)+" ")])])],1)})],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }