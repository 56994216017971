<template>
    <div class="">

        <header id="page-topbar" 
            :style="(theme_color == 'darkblue') ? 'background:#51266e' : 'background: #343a40'">
            
            <div class="navbar-header">
                <div class="d-flex">

                    <!-- LOGO -->
                    <div class="navbar-brand-box">
                        <router-link to="/" class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="/assets/images/mynursery-logo-rounted.png" alt="logo" height="22">
                            </span>
                            <span class="logo-lg">
                                <img src="/assets/images/mynursery-logo-rounted.png" alt="logo" height="70">
                            </span>
                        </router-link>

                        <router-link to="/" class="logo logo-light">
                            <span class="logo-sm">
                                <img src="/assets/images/mynursery-logo-rounded.png" alt="logo" height="22">
                            </span>
                            <span class="logo-lg">
                                <img src="/assets/images/mynursery-logo-rounded.png" alt="logo" height="70">
                            </span>
                        </router-link>
                    </div>

                    <button 
                        type="button" 
                        @click="opnSideNav"
                        class="btn btn-sm px-3 font-size-16 header-item waves-effect d-lg-none" 
                        id="vertical-menu-btn">
                        <i class="fa fa-fw fa-bars"></i>
                    </button>

                </div>

                <div class="d-flex">

                    <!-- <div class="dropdown d-inline-block d-lg-none ml-2">
                        <button 
                            type="button" 
                            class="btn header-item noti-icon waves-effect" 
                            id="page-header-search-dropdown"
                            data-toggle="dropdown" 
                            aria-haspopup="true" 
                            aria-expanded="false">
                            <i class="mdi mdi-magnify"></i>
                        </button>
                        <div 
                            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                            aria-labelledby="page-header-search-dropdown">
                        </div>
                    </div> -->


                    <div class="dropdown d-inline-block">
                        <button 
                            type="button" 
                            class="btn header-item waves-effect" 
                            id="page-header-user-dropdown"
                            data-toggle="dropdown" 
                            aria-haspopup="true" 
                            aria-expanded="false">
                            <img 
                                class="rounded-circle header-profile-user" 
                                :src="auth.avatar"
                                :alt="auth.avatar"
                                style="width: 36px; height:36px;">
                            <span 
                                class="d-none d-xl-inline-block" 
                                :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-16 ml-1' : 'mr-1'"
                                key="t-henry">
                                {{ auth.username }}
                            </span>
                            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </button>

                        <div class="dropdown-menu dropdown-menu-right"
                            :style="($i18n.locale == 'ar') ? '' : 'text-align: left'">
                            <!-- item-->
                            <router-link
                                :to="{ name: 'edit-users', params:{id: auth.encrypt_id}}"
                                class="dropdown-item" 
                                :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-16' : ''">
                                <i class="bx bx-user font-size-16 align-middle mr-1"></i> 
                                <span key="t-profile">{{ $t('app.profile') }}</span>
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <a 
                                class="dropdown-item cursor-pointer" 
                                @click="logout"
                                :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-16' : ''">
                                <i class="bx bx-power-off font-size-16 align-middle mr-1"></i> 
                                <span key="t-logout">{{ $t('app.logout') }}</span>
                            </a>
                        </div>
                    </div>

                    <div class="dropdown d-lg-inline-block "
                        :class="($i18n.locale == 'en') ? 'ml-2' : 'mr-2'">
                        <button 
                            type="button" 
                            class="btn header-item noti-icon waves-effect">
                            <div 
                                v-if="$i18n.locale == 'en'" 
                                @click="switchLang('ar')">
                                <img src="/assets/images/flags/kw.png" style="width: 30px">
                            </div>
                            <div 
                                v-else 
                                @click="switchLang('en')">
                                <img src="/assets/images/flags/uk.png" style="width: 30px">
                            </div>
                        </button>
                    </div>

                    <div class="dropdown d-lg-inline-block ml-1">
                        <button 
                            type="button" 
                            class="btn header-item noti-icon waves-effect">
                            <div 
                                v-if="theme_color == 'darkblue'" 
                                @click="switchTheme('dark')"
                                class="colorPicker darkColor">
                            </div>
                            <div 
                                v-else 
                                @click="switchTheme('darkblue')"
                                class="colorPicker blueColor">
                            </div>
                        </button>
                    </div>


                    <!-- <div class="dropdown d-inline-block">
                        <button 
                            type="button" 
                            class="btn header-item noti-icon right-bar-toggle waves-effect"
                            data-toggle="dropdown" 
                            aria-haspopup="true" 
                            aria-expanded="false">
                            <i class="bx bx-cog bx-spin"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right"
                            :style="($i18n.locale == 'ar') ? '' : 'margin-left: -100px;text-align: left'">
                            <a 
                                class="dropdown-item cursor-pointer" 
                                @click="switchTheme('darkblue')"
                                :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-16' : ''">
                                <span style="background: #00008b;color: #00008b">col</span>
                                <i class="mdi mdi-format-color-fill font-size-18 align-middle mr-1" 
                                    style="color:#00008b">
                                </i> 
                            </a>
                            <div class="dropdown-divider"></div>
                            <a 
                                class="dropdown-item cursor-pointer" 
                                @click="switchTheme('dark')"
                                :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-16' : ''">
                                <span style="background: #000;color: #000">col</span>
                                <i class="mdi mdi-format-color-fill font-size-18 align-middle mr-1" 
                                    style="color:black">
                                </i> 
                            </a>
                            <div class="dropdown-divider"></div>
                            <a 
                                class="dropdown-item cursor-pointer hacen_algeria" 
                                v-if="$i18n.locale == 'ar'"
                                href="javascript:;"
                                @click="switchLang('en')">
                                English
                            </a>
                            <a 
                                class="dropdown-item cursor-pointer hacen_algeria" 
                                v-if="$i18n.locale == 'en'"
                                href="javascript:;"
                                @click="switchLang('ar')">
                                العربية
                            </a>
                        </div>
                    </div> -->


                </div>
            </div>
        </header>
        
    </div>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            auth: {
                role: '',
                encrypt_id: '',
                username: '',
                avatar: '',
                access_token: '',
            },
            theme_color: 'purple'
        }
    },
    mounted() {},
    created() {
        //
        if(localStorage.getItem('encrypt_id')) {
            this.auth.encrypt_id = localStorage.getItem('encrypt_id');
        }
        if(localStorage.getItem('avatar')) {
            this.auth.avatar = localStorage.getItem('avatar');
        }
        if(localStorage.getItem('username')) {
            this.auth.username = localStorage.getItem('username');
        }
        if(localStorage.getItem('access_token')) {
            this.auth.access_token = localStorage.getItem('access_token');
        }
        if(localStorage.getItem('role')) {
            this.auth.role = localStorage.getItem('role');
        }

        if(localStorage.getItem('theme_color')) {
            this.theme_color = localStorage.getItem('theme_color');
            this.switchTheme(this.theme_color);
        }

        if(localStorage.getItem('locale') == 'ar') {
            this.switchLang('ar');
        } else {
            this.switchLang('en');
        }
        if(!localStorage.getItem('locale')) {
            this.switchLang('ar');
        }

    },
    methods: {

        // Logout
        logout(){
            // remove localStorage
            localStorage.removeItem('access_token');
            localStorage.removeItem('avatar');
            localStorage.removeItem('username');
            localStorage.removeItem('user_id');
            localStorage.removeItem('encrypt_id');
            localStorage.removeItem('lecturer_id');
            localStorage.removeItem('role');
            localStorage.removeItem('theme_color');
                
            this.$router.push({ name: 'login' });

            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/auth/logout',
                method: 'POST',
                data: {},
                params: {},
            }
            this.axios(options)
            .then(() => {})
            .catch(() => {})
            .finally(() => {})
        },

        switchTheme(color) {
            localStorage.setItem('theme_color', color);
            this.theme_color = color;

            if(color == 'darkblue') {
                document.body.classList.add('darkblue')
            } else {
                document.body.classList.remove('darkblue')
            }
        },

        // switchLanguages(country) {
        //     //
        //     localStorage.setItem('country_id', country.id);
        //     localStorage.setItem('country_image', (country.image) ? country.image.url : '');
        //     // update 
        //     this.country.id = country.id;
        //     this.country.image = (country.image) ? country.image.url : '';

        //     this.$emit("headerToChild", country.id);
        // },

        switchLang: function(lang) {
            if(lang == 'ar') {
                this.$i18n.locale = 'ar';
                localStorage.setItem('locale', 'ar');
                document.body.classList.remove('ltr')
            } else {
                this.$i18n.locale = 'en';
                localStorage.setItem('locale', 'en');
                document.body.classList.add('ltr')
            }
        },

        //
        opnSideNav(){
            // let el = document.querySelector("body");
            // if(el.classList.has('side-nav-on-action')) {
            //     el.classList.remove('side-nav-on-action');
            // } else {
            //     el.classList.add('side-nav-on-action');
            // }
            if (this.$route.name == 'mobileSidebar') {
                this.$router.push({ name: 'home' });
            } else {
                this.$router.push({ name: 'mobileSidebar' });
            }            
        },

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .colorPicker {
        width: 25px;height: 25px;border:1px solid gray;border-radius: 50%;
    }
    .blueColor {
        background: #51266e !important;
    }
    .darkColor {
        background: #343a40 !important;
    }
</style>
