const refs = 'errors'
export default [
    
    { 
        path: '/forbidden',
        name: 'forbidden', 
        component: () => import('@/views/'+refs+'/Forbidden.vue'),
        beforeEnter: requireAuth 
    },
    { 
        path: '*',
        redirect: '/404',
        hidden: true,
    },
    { 
        path: '/404',
        name: '404',
        component: () => import('@/views/'+refs+'/NotFound.vue'),
    }
    
]



function requireAuth(to, from, next) {
    if(!localStorage.getItem('access_token')) { next('/login'); }
    else { next(); }
}