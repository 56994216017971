<template>
    <div id="app">

        
        <div v-if="!authPages" id="layout-wrapper">

            <Header></Header>
            <Sidebar></Sidebar>

            <div class="main-content">
                <router-view></router-view>
            </div>

        </div>

        <div v-else class="account-pages my-5 pt-sm-5">
            <router-view/>
        </div>

    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
    name: 'App',
    components: {
        Header,
        Sidebar
    },
    data() {
        return {
            //
            authPages: false,
        }
    },
    watch: {
        $route() {
            if(this.$route.path  == '/login' || 
                this.$route.path == '/forget/password' ||
                this.$route.path == '/reset/password' ||
                this.$route.path == '/404' ||
                this.$route.path == '/forbidden') {
                this.authPages = true;
            } else {
                this.authPages = false;
            }
        },
    },
    mounted() {
        // set om el default
        //console.log('first call.');
        //if(!localStorage.getItem('locale')) {
            this.$i18n.locale = 'ar';
            localStorage.setItem('locale', 'ar');
            document.body.classList.remove('ltr')
        //}
    },
    created() {
        //
    },
    methods: {
        //
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
