import Vue from 'vue'
import Router from 'vue-router'

// mixen routers
import AuthRoutes from '@/router/modules/auth'
import AppRoutes from '@/router/modules/app'
import ErrorRoutes from '@/router/modules/error'
import LogsRoutes from '@/router/modules/logs'
//
import AppointmentConsultationRoutes from '@/router/modules/appointmentConsultation'
import BookRoutes from '@/router/modules/book'
import CategoryRoutes from '@/router/modules/category'
import ChatConsultationRoutes from '@/router/modules/chatConsultation'
import CouponRoutes from '@/router/modules/coupon'
import EmergencyConsultationRoutes from '@/router/modules/emergencyConsultation'
import FaqRoutes from '@/router/modules/faq'
import financialDuesRoutes from '@/router/modules/financialDues'
import LecturerRoutes from '@/router/modules/lecturer'
import MessageRoutes from '@/router/modules/message'
import NotificationRoutes from '@/router/modules/notification'
import OnlineCourseRoutes from '@/router/modules/onlineCourse'
import OfflineCourseRoutes from '@/router/modules/offlineCourse'
import DiplomatsRoutes from '@/router/modules/diplomats'
import PageRoutes from '@/router/modules/page'
import ReportRoutes from '@/router/modules/report'
import RoleRoutes from '@/router/modules/role'
import SettingRoutes from '@/router/modules/setting'
import SplashRoutes from '@/router/modules/splash'
import UserRoutes from '@/router/modules/user'
import EmployeeRoutes from '@/router/modules/employee'
import VideoConsultationRoutes from '@/router/modules/videoConsultation'
import MeetingConventionsRoutes from '@/router/modules/meetingConventions'
import VoiceConsultationRoutes from '@/router/modules/voiceConsultation'
import ServiceRoutes from '@/router/modules/service'
import TeamRoutes from '@/router/modules/team'
import ContactRoutes from '@/router/modules/contact'
import AppSettingRoutes from '@/router/modules/appSetting'
import workFieldRoutes from '@/router/modules/workField'
import sliderRoutes from '@/router/modules/slider'
import mediaSetting from '@/router/modules/mediaSetting'

var allRoutes = [];
allRoutes = allRoutes.concat(
    AuthRoutes,
    AppRoutes,
    ErrorRoutes,
    LogsRoutes,
    //
    AppointmentConsultationRoutes,
    mediaSetting,
    BookRoutes,
    CategoryRoutes,
    workFieldRoutes,
    sliderRoutes,
    ContactRoutes,
    AppSettingRoutes,
    ServiceRoutes,
    TeamRoutes,
    ChatConsultationRoutes,
    CouponRoutes,
    EmergencyConsultationRoutes,
    FaqRoutes,
    financialDuesRoutes,
    LecturerRoutes,
    MessageRoutes,
    NotificationRoutes,
    OnlineCourseRoutes,
    OfflineCourseRoutes,
    DiplomatsRoutes,
    PageRoutes,
    ReportRoutes,
    RoleRoutes,
    SettingRoutes,
    SplashRoutes,
    UserRoutes,
    EmployeeRoutes,
    VideoConsultationRoutes,
    MeetingConventionsRoutes,
    VoiceConsultationRoutes
    //
)

Vue.use(Router)
export default new Router({
    mode: 'history',
    base: '/',
    fallback: true,
    routes: allRoutes
})

