<template>
  <div class="">
    <div class="vertical-menu">
      <div data-simplebar class="h-100">
        <div id="sidebar-menu">
          <ul class="metismenu list-unstyled" id="side-menu">
            <li
              class="menu-title font-size-14"
              key="t-menu"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
            >
              {{ $i18n.locale == "ar" ? "القائمة" : "Menu" }}
            </li>

            <li v-if="!pgLoading">
              <router-link
                to="/"
                class="waves-effect"
                :class="[
                  $i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-17'
                    : 'font-size-15',
                  this.$route.path == '/' ? 'active' : '',
                ]"
              >
                <i class="bx bx-customize font-size-22"></i>
                <span key="t-dashboards">
                  {{ $t("nav.dashboard") }}
                </span>
              </router-link>
            </li>

            <li v-for="(item, idx) in items" :key="idx">
              <router-link
                :to="'/' + item.slug"
                active-class="active"
                class="waves-effect"
                :class="[
                  $i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-17'
                    : 'font-size-15',
                  item.authority ? '' : 'hidden',
                ]"
              >
                <i :class="item.icon" class="font-size-22"></i>
                <span key="t-dashboards">
                  {{ $i18n.locale == "en" ? item.en.name : item.ar.name }}
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "Sidebar",
  data() {
    return {
      auth: {
        access_token: "",
      },
      pgLoading: false,
      items: [],
    };
  },
  mounted() {},
  created() {
    //
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    if (localStorage.getItem("access_token")) {
      this.fetchSidebar();
    }
  },
  methods: {
    //
    fetchSidebar() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
        locale: this.$i18n.locale,
      };
      const options = {
        url: window.baseURL + "/applications",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.items = res.data.data.rows;
        })
        .catch((err) => {
          this.pgLoading = false;
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },
  },
};
</script>
