import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    local: 'ar',
    locale: 'ar',
    fallbackLocale: 'ar',
    silentTranslationWarn: true,
    messages: {

        /* english */
        en: {
            auth: {
                email_address: 'Email address',
                mobile: 'Mobile',
                password: 'Password',
                remember_me: 'Remember me',
                forget_password: 'Forget Password ?',
                login: 'Login',
                return_login: 'return to login ?',
                submit: 'Submit',
                key_code: 'Key Code',
                new_password: 'New Password',
                con_new_password: 'Confirm New Password',
            },

            nav: {
                dashboard: 'Dashboard',
                emergencyConsultations: 'Emergency Consultations',
                appointmentConsultations: 'Appointment Consultations',
                workFields: 'Work Fields',
                slider: 'Slider',
                areYouSure: 'Are You Sure ?',
                books: 'Books',
                bookSales: 'Book Sales',
                categories: 'Categories',
                services: 'Services',
                contact: 'Contact',
                team: 'Team',
                chatConsultations: 'Chat Consultations',
                coupons: 'Coupons',
                faqs: 'Faqs',
                lecturers: 'Lecturers',
                messages: 'Messages',
                notifications: 'Notifications',
                onlineCourses: 'Online Courses',
                onlineCoursesAttendance: 'Online Courses Attendance',
                offlineCourses: 'Offline Courses',
                certificates: 'Certificates',
                educationalProgram: 'Educational Program',
                offlineCoursesAttendance: 'Offline Courses Attendance',
                pages: 'Pages',
                reports: 'Reports',
                roles: 'Roles',
                settings: 'Settings',
                appSettings: 'App Settings',
                financialDues: 'Financial Dues',
                splashes: 'Splashes',
                users: 'Users',
                employees: 'Employees',
                combination_reports: 'Combination Reports',
                mediaSettings: 'Media Settings',
                videoConsultations: 'Video Consultations',
                meetingConventions: 'Meeting Convention',
                voiceConsultations: 'Voice Consultations'
            },

            app: {
                welcome: 'Welcome at ALOSAMAI PEARL Archiving System.',
                copyright: 'copyright all right reserved © 2021 -',
                gatetechs: 'Gatetechs',
                cost: 'Cost',
                detail: 'Detail',
                choose_module: 'Choose Module',
                choose_course: 'Choose Course',
                module: 'Module',
                percentage: 'Percentage %',
                coupons: 'Coupons',
                couponCount: 'Coupons Code',
                side: 'Side',
                choose_report: 'Choose Report',
                search_by_name_or_email_or_phone: 'Search via name, email or phone',
                notes_to_course_members: 'The Notification will send to all users who bought with that course',
                notes_to_book_members: 'The Notification will send to all users who bought with that book',
                choose_user: 'Choose User',
                choose_lecturer: 'Choose Lecturer',
                choose_category: 'Choose Category',
                choose_members: 'Choose Members',

                talking_on_whatsapp: 'Talking on WhatsApp',
                withdrawal: 'Withdrawal',
                deposit: 'Deposit',
                currentBalance: 'Current Balance',
                type: 'Type',

                months: 'Months',
                weeks: 'Days',
                times: 'Times',
                timesAndPrices: 'Times & Prices',
                periods: 'Periods',
                period_per_minutes: 'Periods per minutes',
                period_per_hours: 'Periods per hours',
                question_price: 'Question Price',
                consult_price: 'Consult Price',

                just_approve: 'Approve',
                just_decline: 'Decline',
                delayed: 'Delayed',
                has_delayed: 'Delayed',
                approved: 'Approved',
                unapproved: 'UnApproved',
                approve: 'Approve',
                decline: 'Decline',
                new: 'Pending',
                time: 'Time',
                time_from: 'Time From',
                time_to: 'Time To',
                period: 'Period',
                minutes: 'Minutes',
                hours: 'hours',
                price: 'Price',
                question: 'Question',
                answer: 'Answer',
                //kw: 'kw',
                kw: 'usd',
                paid: 'Paid',

                payment_success: 'Success',
                payment_not_success: 'Not yet',

                youtube_link: 'Youtube Link',
                youtube_links: 'Youtube Links',
                youtube: 'Youtube',
                certificate_file: 'Certificate File',
                other_youtube_link: 'Other youtube links',
                intro_youtube_link: 'Intro Youtube Link',

                lecturer_not_called: "There's no call found.",
                lecturer_has_called: 'Call has been successfully',
                user_not_answer_yet: "User didn't pick up",
                user_has_answer: 'User pickup successfully',

                bond_types: 'Financial Bond Type',
                companies: 'Companies',
                contracts: 'Contracts',
                check_number: 'Check Number',
                please_specify_reject_reason: 'Please specify reject reason',
                please_specify_delayed_dateTime: 'Please specify delayed date and time',
                waiting_for_customer_approve: 'Waiting for customer approve',
                customer_has_approve: 'Customer has approved',
                customer_has_decline: 'Customer has decline',
                notify_msg: 'Notification message',

                show_attachment: 'Show File',
                add_new: 'Add New',
                contact: 'Contact',
                all: 'All',
                hello: 'Hello',
                profile: 'Profile',
                logout: 'Logout',
                purple: 'Purple',
                dark: 'Dark',
                update: 'Update',
                deservedAmount: 'Deserved Amount',
                totalAmount: 'Total Amount',
                send: 'Send',
                save: 'Save',
                create: 'Create',
                show: 'Show',
                back: 'Back',
                cancel: 'Cancel',
                active: 'Active',
                seen: 'Seen',
                unseen: 'Unseen',
                inactive: 'Inactive',
                from: 'From',
                to: 'To',
                excel: 'Excel',
                csv: 'CSV',
                print: 'Print',
                upload_excel: 'Upload XLSX',
                edit: 'Edit',
                addNew: 'Add New',
                addCourseToUser: 'Add Course To User',
                dashboard: 'Dashboard',
                search: 'Search...',
                search_by_name: 'Search by name',
                arabic: 'Arabic',
                english: 'English',
                balance: 'Balance',

                all_rows: 'All',
                showInHeader: 'Show In Header',
                showInFooter: 'Show In Footer',
                showInSupport: 'Show In Support',

                start_date: 'Start date',
                end_date: 'End Date',
                start_time: 'Start Time',
                end_time: 'End Time',
                invoice_date: 'Invoice Date',
                bond_date: 'Date',
                date: 'Date',
                progress: 'Progress',
                actions: 'Settings',
                status: 'Status',
                sort: 'Sort',
                rate: 'Rate',
                star: 'Star',
                stars: 'Stars',
                upload: 'Upload',
                upload_attachment: 'Upload Attachment',
                change_upload: 'Change Image',
                change_upload_attachment: 'Change Attachment',


                social: 'Social Media',
                facebook: 'Facebook',
                twitter: 'Twitter',
                linkedin: 'Linkedin',

                export: 'Export',
                bulkActions: 'Bulk Actions',

                // msgs
                success_title: 'Great job,',
                success_msg: 'Request Completed Successfully.',
                warning_title: 'Wow, man',
                warning_msg: 'Slow down, ',
                error_title: 'Wow-wow,',
                error_msg: 'Something went wrong.',
                no_data: 'No data found.',
                invalid_credentials: 'Invalid Credentials',

            },

            view: {
                role: 'Role',
                user: 'User',
                attachemnts: 'Attachments',
                bill_attachment: 'Bill Attachment',
                work_fields_info: 'Work Fields Info',
                media_settings_info: 'Media Settings Info',
                slider_info: 'Slider Info',
                user_wallet: 'User Wallet',
                user_certificate: 'Add Certificate',

                book_info: 'Book Info',
                book_title: 'Book Title',
                book_body: 'Book Description',
                book_image: 'Book Image',
                book_price: 'Book Price',
                book_qty: 'Qty',
                offer_image: 'Offer Image',
                published_at: 'Published at',
                video_link: 'Video Link',
                video_id: 'Video ID',

                paid: "Paid",
                notPaid: "Not Paid",

                app_name: 'App Name',
                mission: 'Our Word',
                goal: 'Our Vision',
                address: 'Address',

                coupon_info: 'Coupon Info',
                coupon_title: 'Coupon Title',
                coupon_body: 'Coupon Description',

                service_info: 'Service Info',
                service_title: 'Service Title',
                service_body: 'Service Body',

                subject: "Subject",

                team_info: 'Team Member Info',
                team_name: 'Team Member Name',
                job: 'Job',

                lecturer: 'Lecturer',
                lecturer_info: 'Lecturer Info',
                lecturer_image: 'Lecturer Image',
                lecturer_name: 'Lecturer Name',
                lecturer_position: 'Lecturer Name',
                lecturer_university: 'University',
                lecturer_body: 'Other details',
                lecturer_background: 'Backgrounds',

                messages_info: 'Messages Info',

                notifications: 'Notifications',

                course_info: 'Course Info',
                course_title: 'Course Title',
                course_image: 'Course Image',
                course_body: 'Course Description',
                course_price: 'Price',
                certificate_type: 'Certificate Type',
                course_attendance: 'Attendance',
                course_attendances_no: 'Attendance No.',

                page_info: 'Page Info',
                page_title: 'Page Title',
                page_body: 'Page Description',

                category_info: 'Category Info',
                category_title: 'Category Title',
                category_body: 'Category Description',

                setting_info: 'Setting Info',
                splash_info: 'Splash Info',

                username: 'Username',
                email: 'Email Address',
                ccode: 'Country Code',
                mobile: 'Mobile',
                image: 'Image',

                user_info: 'User Info',

                permissions: 'Permissions',
                role_info: 'Role Info',

                role_name: 'Role Name',
                password: 'Password',
                users_no: 'Users No.',
                name: 'Name',
                title: 'Title',
                body: 'Description',
                addMore: 'Add More',
                date_is_old: 'Date in the past',
                noPermissionGranted: 'No permission granted',
            },


            role: {
                add: 'create',
                edit: 'update',
                delete: 'delete',
                viewRow: 'View Row',
                view: 'view',
                block: 'Block',
                unblock: 'Unblock',
            },

            chart: {
                total_appointments: 'Total Appointments',
                total_chats: 'Total Chats',
                total_videos: 'Total Videos',
                total_voices: 'Total Voices',

                total_books: 'Total Books',
                total_online: 'Total Online Courses',
                total_offline: 'Total Offline Courses',
                total_faqs: 'Total FAQs',
                total_meeting_conventions: 'Total Meeting Conventions',
                total_educational_program: 'Total Educational programs',

                users_during_the_year: 'App Downloads during the year',
            },

            // Month Names
            months: {
                January: 'January',
                February: 'February',
                March: 'March',
                April: 'April',
                May: 'May',
                June: 'June',
                July: 'July',
                August: 'August',
                September: 'September',
                October: 'October',
                November: 'November',
                December: 'December',
            },

            // weekdays Name
            weekDays: {
                Sunday: 'Sunday',
                Monday: 'Monday',
                Tuesday: 'Tuesday',
                Wednesday: 'Wednesday',
                Thursday: 'Thursday',
                Friday: 'Friday',
                Saturday: 'Saturday',
            }

        },


        /* Arabic */
        ar: {

            auth: {
                email_address: 'البريد الإلكتروني',
                mobile: 'رقم الجوال',
                password: 'كلمة المرور',
                remember_me: 'تذكرني',
                forget_password: 'نسيت كلمة المرور ؟',
                login: 'تسجيل دخول',
                return_login: 'رجوع لتسجيل الدخول؟',
                submit: 'إرسالة',
                key_code: 'الكود',
                new_password: 'كلمة المرور الجديدة',
                con_new_password: 'تأكيد كلمة المرور',
            },

            nav: {
                dashboard: 'لوحة التحكم',
                emergencyConsultations: 'استشارات طارئة',
                appointmentConsultations: 'مواعيد عيادة',
                workFields: 'مجالات العمل',
                slider: 'سلايدر',
                areYouSure: 'هل انت متاكد ؟',

                books: 'الكتب',
                bookSales: 'مبيعات الكتب',
                categories: 'أصناف الكتب',
                services: 'الخدمات',
                contact: 'التواصل',
                team: 'الفريق',
                chatConsultations: 'استشارات نصية',
                coupons: 'أكواد الخصم',
                faqs: 'سؤال وجواب',
                lecturers: 'المحاضرين',
                messages: 'الرسائل',
                notifications: 'الإشعارات',
                onlineCourses: 'دوارات البث المباشر',
                onlineCoursesAttendance: 'حجوزات دوارات البث المباشر',
                offlineCourses: 'دوارات المسجلة',
                certificates: 'الشهادات',
                educationalProgram: 'البرنامج التربوي',
                offlineCoursesAttendance: 'حجوزات دوارات المسجلة',
                pages: 'الصفحات',
                reports: 'التقارير',
                roles: 'الصلاحيات',
                settings: 'الإعدادات',
                appSettings: ' إعدادات التطبيق',
                financialDues: 'المستحقات',
                splashes: 'مقدمة التطبيق',
                users: 'الأعضاء',
                employees: 'الموظفيين',
                combination_reports: 'التقارير المجمعة',
                mediaSettings: 'اعدادات الميديا',
                videoConsultations: 'استشارات فيديو',
                meetingConventions: ' انعقاد دوره ',
                voiceConsultations: 'استشارات صوتية'
            },

            app: {
                welcome: 'مرحبا بك في لؤلؤة العصيمي ارشيف سيستم',
                copyright: 'جميع الحقوق محفوظة © 2021 -',
                gatetechs: 'بوابة التقنية',
                cost: 'المبلغ',
                detail: 'التفاصيل',
                choose_module: 'أختر القسم',
                choose_course: 'أختر الدورة',
                module: 'القسم',
                percentage: 'النسبة ٪',
                coupons: 'أكواد الخصم',
                couponCount: 'عدد أكواد الخصم',
                side: 'الجهه',
                choose_report: 'أختر نوع التقرير',
                search_by_name_or_email_or_phone: 'بحث بالأسم او الإيميل او رقم الجوال',

                notes_to_course_members: 'سيتم إرسال الإشعار إلى جميع المستخدمين الذين اشتروا هذه الدورة التدريبية',
                notes_to_book_members: 'سيتم إرسال الإشعار إلى جميع المستخدمين الذين اشتروا هذا الكتاب',

                choose_user: 'أختر المستخدم',
                choose_lecturer: 'أختر المحاضر',
                choose_category: 'أختر الصنف',
                choose_members: 'أختر الأعضاء',

                talking_on_whatsapp: 'التحدث عبر الواتساب',
                withdrawal: 'سحب',
                deposit: 'أضف رصيد',
                currentBalance: 'الرصيد الحالى',
                type: 'نوع العملية',

                months: 'الشهور',
                weeks: 'أيام الأسبوع',
                times: 'المواعيد',
                timesAndPrices: 'المواعيد والأسعار',
                periods: 'المدة',
                period_per_minutes: 'المدة (بالدقائق)',
                period_per_hours: 'المدة (بالساعات)',
                question_price: 'سعر السؤال',
                consult_price: 'سعر الإستشارة',

                just_approve: 'موافقة',
                just_decline: 'رفض',
                delayed: 'تأجيل',
                has_delayed: 'مؤجل',
                approved: 'تم الموافقة',
                approve: 'موافقة',
                decline: 'رفض',
                unapproved: 'تم الرفض',
                new: 'قيد الإنتظار',
                time: 'الوقت',
                time_from: 'الوقت من',
                time_to: 'الوقت إلي',
                period: 'المدة',
                hours: 'ساعة',
                minutes: 'دقيقة',
                price: 'السعر',
                certificates: 'نوع الشهاده',
                question: 'السؤال',
                answer: 'الإجابة',
                //kw: 'دينار',
                kw: 'دولار',
                paid: 'الدفع',
                payment_success: 'تم الدفع',
                payment_not_success: 'لم يتم الدفع',

                youtube_link: 'رابط اليوتيوب (الدورة)',
                youtube_links: 'روابط اليوتيوب (الدورة)',
                youtube: 'رابط اليوتيوب',
                certificate_file: 'ملف الشهادة',
                other_youtube_link: 'روابط آخري (الدورة)',
                intro_youtube_link: 'رابط اليوتيوب (فيديو ترويجى)',

                lecturer_not_called: 'لم يتم الإتصال من المحاضر حتي الآن',
                lecturer_has_called: 'تم الإتصال من المحاضر',
                user_not_answer_yet: 'لم يتم الرد من العضو',
                user_has_answer: 'تم الرد من العميل',


                bond_types: 'أنواع السندات المالية',
                companies: 'الشركات',
                contracts: 'العقود',
                check_number: 'رقم الشيك',
                please_specify_reject_reason: 'من فضلك حدد سبب الرفض',
                please_specify_delayed_dateTime: 'من فضلك حدد الوقت وميعاد التأجيل',
                waiting_for_customer_approve: 'بإنتاظار موافقة العميل',
                customer_has_approve: 'تم الموافقة من العميل',
                customer_has_decline: 'تم الرفض من العميل',
                notify_msg: 'الإشعار',

                show_attachment: 'عرض الملف',
                add_new: 'إضافة جديد',
                contact: 'التواصل',
                all: 'الكل',
                hello: 'مرحباً ',
                profile: 'حسابي',
                logout: 'تسجيل خروج',
                purple: 'بنفسجي',
                dark: 'الأسود',
                update: 'تعديل',
                deservedAmount: "المبلغ المستحق",
                totalAmount: "المبلغ الإجمالي",
                save: 'حفظ التعديلات',
                send: 'إرسال',
                create: 'إنشاء',
                show: 'إظهر',
                back: 'رجوع',
                cancel: 'إلغاء',
                active: 'نشط',
                seen: 'تم الإجابة',
                unseen: 'رسالة جديدة',
                inactive: 'غير نشط',
                from: 'من',
                to: 'إلي',
                excel: 'تنزيل ملف XLS',
                csv: 'تنزيل ملف CSV',
                print: 'طباعة الجدول',
                upload_excel: 'رفع ملف XLSX',
                edit: 'تعديل',
                analytics: 'إحصائيات',
                addNew: 'أضف جديد',
                addCourseToUser: 'أضف الدورة للعضو',
                dashboard: 'لوحة التحكم',
                search: 'بحث...',
                search_by_name: 'بحث باسم العضو...',
                arabic: 'عربي',
                english: 'إنجليزي',
                balance: 'الرصيد',

                all_rows: 'الكل',
                showInHeader: 'إظهر في أعلي الصفحة',
                showInFooter: 'إظهر في أسفل الصفحة',
                showInSupport: 'إظهر في قسم الدعم',

                start_cdate: 'تاريخ التعاقد',
                end_cdate: 'تاريخ الإنتهاء',
                start_date: 'تاريخ البدأ',
                end_date: 'تاريخ الإنتهاء',
                start_time: 'وقت البدأ',
                end_time: 'وقت الإنتهاء',
                notebookExpireDate: 'تاريخ إنتهاء صلاحية الدفتر',
                invoice_date: 'تاريخ الفاتورة',
                bond_date: 'تاريخ السند',
                date: 'التاريخ',
                progress: "تم مشاهدة",
                actions: 'الإعدادات',
                status: 'الحالة',
                sort: 'الترتيب',
                rate: 'التقييم',
                star: 'نجمة',
                stars: 'النجوم',
                upload: 'أرفع الصورة',
                change_upload: 'تعديل الصورة',

                upload_attachment: 'أرفع الملف',
                change_upload_attachment: 'تعديل الملف',


                social: 'التواصل الإجتماعي',
                facebook: 'فيس بوك',
                twitter: 'توتير',
                linkedin: 'لينكد إن',

                export: 'مدير الملفات',
                bulkActions: 'جملة الإعدادات',

                // msgs
                success_title: 'عمل رائع,',
                success_msg: 'تم تنفيذ طلبك بنجاح',
                warning_title: 'أنتظر من فضلك',
                warning_msg: '',
                error_title: 'خطأ',
                error_msg: 'حدث خطأ من الخادم من فضلك حاول مرة آخري',
                no_data: 'لا يوجد بيانات',
                invalid_credentials: 'البيانات غير صحيحة',

                month: 'شهر',
                year: 'سنة',

                january: 'يناير',
                february: 'فبراير',
                march: 'مارس',
                april: 'ابريل',
                may: 'مايو',
                june: 'يونيو',
                july: 'يوليو',
                august: 'أغسطس',
                september: 'سبتمير',
                october: 'أكتوبر',
                november: 'نوفمبر',
                december: 'ديسمبر',

                sunday: 'الأحد',
                monday: 'الأثنين',
                tuesday: 'الثلاثاء',
                wedensday: 'الأربع',
                thursday: 'الخميس',
                friday: 'الجمعة',
                saturaday: 'السبت',

                absent: 'غائب',
                reward: 'مكافئة',
                vacation: 'أجازة'

            },

            view: {
                role: 'صلاحية',
                user: 'العضو',
                attachemnts: 'المرفقات',
                bill_attachment: 'مرفقات الفاتورة',
                work_fields_info: 'معلومات عن مجال العمل',
                media_settings_info: 'معلومات عن اعدادات الميديا',
                slider_info: 'معلومات عن السلايدر',
                user_wallet: 'محفظة العضو',
                user_certificate: 'أضف شهادة',

                book_info: 'تفاصيل الكتاب',
                book_title: 'أسم الكتاب',
                book_body: 'وصف الكتاب / مقدمة',
                book_image: 'صورة الغلاف',
                book_price: 'السعر',
                book_qty: 'الكمية',
                offer_image: 'صورة الخصم',
                published_at: 'تاريخ النشر',
                video_link: 'رابط الفيديو',
                video_id: 'معرف الفيديو',
                paid: "مدفوعة",
                notPaid: "غير مدفوعة",

                mission: 'كلمتنا',
                goal: 'رؤيتنا',
                address: 'العنوان',
                app_name: 'اسم التطبيق',

                coupon_info: 'تفاصيل اكواد الخصم',
                coupon_title: 'أسم المناسبة',
                coupon_body: 'مقدمة',

                service_info: 'تفاصيل الخدمة',
                service_title: 'عنوان الخدمة',
                service_body: ' وصف الخدمة',

                subject: "الموضوع",

                team_info: 'تفاصيل عضو الفريق',
                team_name: 'اسم عضو الفريق',
                job: 'الوظيفة',

                lecturer: 'المحاضر',
                lecturer_info: 'تفاصيل المحاضر',
                lecturer_image: 'صورة المحاضر',
                lecturer_name: 'أسم المحاضر',
                lecturer_position: 'الوظيفة',
                lecturer_university: 'الجامعة',
                lecturer_body: 'تفاصيل آخري',
                lecturer_background: 'صور الخلفية',

                messages_info: 'تفاصيل الرسالة',

                notifications: 'الإشعارات',

                course_info: 'تفاصيل الدورة',
                course_title: 'أسم الدورة',
                course_image: 'الصورة',
                course_body: 'تفاصيل الدورة',
                course_price: 'سعر الدورة',
                certificate_type: 'انواع الشهادات',
                course_attendance: 'الحضور',
                course_attendances_no: 'عدد الحضور المسموح في الدورة',

                page_info: 'تفاصيل الصفحة',
                page_title: 'أسم الصفحة',
                page_body: 'وصف الصفحة',

                category_info: 'تفاصيل الصنف',
                category_title: 'أسم الصنف',
                category_body: 'وصف الصنف',

                setting_info: 'تفاصيل الإعدادات',
                splash_info: 'تفاصيل الإسبلاش',

                username: 'أسم العضو',
                email: 'البريد الإلكتروني',
                ccode: 'كود الدولة',
                mobile: 'رقم الجوال',
                image: 'الصورة',

                user_info: 'تفاصيل العضو',
                employee_info: 'تفاصيل الموظفة',
                employee_name: 'أسم الموظف',

                permissions: 'الصلاحيات',
                role_info: 'تفاصيل الصلاحية',

                role_name: 'أسم الصلاحية',
                password: 'كلمة المرور',
                users_no: 'عدد الأعضاء',
                name: 'الأسم',
                title: 'العنوان',
                body: 'الوصف',
                addMore: 'أضف المزيد',
                date_is_old: 'تاريخ الحالي اكبر من تاريخ الطلب',
                noPermissionGranted: 'ليس لديك صلاحيات',
            },

            role: {
                add: 'إضافة',
                edit: 'تعديل',
                delete: 'حذف',
                viewRow: 'مشاهدة عنصر',
                view: 'مشاهدة',
                block: 'تعليق الحساب',
                unblock: 'إلغاء تعليق الحساب'
            },

            chart: {
                total_appointments: 'إجمالي مواعيد العيادة',
                total_chats: 'إجمالي الإستشارات النصية',
                total_videos: 'إجمالي الإستشارات الفيديو',
                total_voices: 'إجمالي الإستشارات الصوتية',

                total_books: 'إجمالي الكتب',
                total_online: 'إجمالي الدورات الأونلاين',
                total_offline: 'إجمالي الدورات المسجلة',
                total_faqs: 'إجمالي السؤال والإجابة',
                total_meeting_conventions: 'إجمالي الدورات المنعقدة ',
                total_educational_program: 'إجمالي البرامج التربوية ',

                users_during_the_year: 'إحصائيات مستخدمين التطبيق خلال السنة',
            },

            // Month Names
            months: {
                January: 'يناير',
                February: 'فبراير',
                March: 'مارس',
                April: 'ابريل',
                May: 'مايو',
                June: 'يونيو',
                July: 'يوليو',
                August: 'أغسطس',
                September: 'سبتمبر',
                October: 'أكتوبر',
                November: 'نوفمر',
                December: 'ديسمبر',
            },

            // weekdays Name
            weekDays: {
                Sunday: 'الأحد',
                Monday: 'الأثنين',
                Tuesday: 'الثلاثاء',
                Wednesday: 'الأربع',
                Thursday: 'الخميس',
                Friday: 'الجمعة',
                Saturday: 'السبت',
            }

        },
    }
})