const refs = 'auth'
export default [
    
    { 
        path: '/login', 
        name: 'login', 
        component: () => import('@/views/'+refs+'/Login.vue'), 
        beforeEnter: requireUnAuth 
    },
    { 
        path: '/forget/password', 
        name:'forget', 
        component: () => import('@/views/'+refs+'/Forget.vue'), 
        beforeEnter: requireUnAuth 
    },
    { 
        path: '/reset/password', 
        name:'reset', 
        component: () => import('@/views/'+refs+'/Reset.vue'), 
        beforeEnter: requireUnAuth 
    },

]



function requireUnAuth(to, from, next) {
    if(!localStorage.getItem('access_token')) { next(); }
    else { next('/'); }
}