import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import plugins from '@/plugins'
import { i18n } from '@/translation'

// baseURL 
window.baseURL = process.env.VUE_APP_baseURL;
Vue.config.productionTip = false;
 
// Handle all Vue errors
Vue.config.errorHandler = (error) => console.log(error);

new Vue({
  i18n,
  plugins,
  router,
  render: h => h(App),
}).$mount('#app')

