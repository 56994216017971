import Vue from 'vue'

/* Vue Axios */
import VueAxios from 'vue-axios';
import axios from 'axios';
Vue.use(VueAxios, axios);
/* End Vue Axios */

/* IziToast */
import 'izitoast/dist/css/iziToast.min.css';
import iziToast from 'izitoast';
Vue.use(iziToast);
/* End IziToast */

/* Vue Download Json Excel  */
import JsonExcel from 'vue-json-excel'; 
Vue.component('downloadExcel', JsonExcel);
/* End Vue Download Json Excel */

/* Copy to Clipboard */
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
/* End Copy to Clipboard */

/* Vue Print-nb */
import Print from 'vue-print-nb';
Vue.use(Print);
/* End Vue Print-nb */

/* Axios PLugin */
import AxiosPlugin from 'vue-axios-cors';
Vue.use(AxiosPlugin)
/* End Axios PLugin */

/* VueApexCharts */
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
/* End VueApexCharts */

/* Vue Tooltip */
import VTooltip from "v-tooltip";
Vue.use(VTooltip);
/* End VueTooltip */

/* Vue Paginate */
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)
/* End Vue Paginate */

/* Vue Search Select */
import 'vue-search-select/dist/VueSearchSelect.css'
/* End Vue Search Select */

/* Vue Editor */
window.editor_apiKey  = 'h8q4goz7y7niwnc8nze5ag56em7sy9yb76ca3uh2v5gakhdy';
window.editor_menubar = 'file edit view insert format tools table help';
window.editor_plugins = [
                         'advlist autolink lists link image charmap print preview anchor',
                         'searchreplace visualblocks code fullscreen',
                         'insertdatetime media table paste code help wordcount'
                        ];
window.editor_toolbar = 'undo redo | formatselect | bold italic backcolor | \
                         alignleft aligncenter alignright alignjustify | \
                         bullist numlist outdent indent | removeformat | help';
/* Vue End Editor */

export default []