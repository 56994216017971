const refs = 'logs'
export default [
    
    { 
        path: '/'+refs, 
        name: refs, 
        component: () => import('@/views/'+refs+'/List.vue'),
        beforeEnter: requireAuth 
    },

]



function requireAuth(to, from, next) {
    if(!localStorage.getItem('access_token')) { next('/login'); }
    else { next(); }
}